  $(document).ready(function() {

    // if ($('.annonce-moreimages').length > 0) {
    //     new Splide('.annonce-moreimages', {
    //         perPage: 3,
    //         breakpoints: {
    //             576: {
    //                 perPage: 2,
    //             },
    //         },
    //         //height: '200px',
    //         autoHeight: true,
    //         start: 0,
    //         type: 'loop',
    //         autoplay: true,
    //         pagination: false,
    //         gap: '0.5rem',
    //     }).mount();
    // }
     
    // Permets lors d'un click sur l'image du slider de remplacer l'image principale
      $('#page_borne_recharge_details').on('click', '.slider-refresh', function() {
          var url = $(this).attr('src');
          var clicked = $(this).attr('src').split('/borne/');

          $('.annonce-pictures').each(function() {
              if ($(this).is(':parent') && $(this).children().attr("src") == url) {
                  $(this).children().trigger('click');
                  return false;
              }
          });

          $('.annonce-pictures').each(function() {
              if ($(this).attr('href').includes(clicked[1]) == false) {
                  $(this).empty();
              } else {
                  $(this).html('<img id="annonce-zoom" class="img-fluid" src="' + url + '"/>');
              }
          });
          $('#annonce-zoom').attr("src", url);
          $('#annonce-zoom').parent().attr("href", url);
      });

      $('#page_borne_recharge_details').on('click', '.annonce-caracteristiques-button', function() {
          var clicked = this.id.replace('annonce-caracteristiques-button-', '');

          // On supprime les classes pour le bouton actif
          $(".annonce-caracteristiques-button").each(function() {
              if ($(this).hasClass('selected')) {
                  $(this).removeClass('selected');
                  $(this).find('.bg-bluelight').removeClass('bg-bluelight').removeClass('text-white');
                  $(this).find('.triangle-vehicule').find('div').addClass('d-none');
              }
          });
          // On ajoute les classes pour le nouveau bouton actif
          $(this).addClass('selected');
          $(this).find('div').first().addClass('bg-bluelight').addClass('text-white');
          $(this).find('.triangle-vehicule').find('div').removeClass('d-none');


          // On modifie le 'content' visible
          $("[id^='annonce-caracteristiques-content-']:visible").each(function() { $(this).slideUp(); })
          $("#annonce-caracteristiques-content-" + clicked).slideDown();
      });

      //Envoi de mail
      $('#mail-borne').on('click', function() {
          email = $("#email").val();
          data_borne = $('#mail-borne').data('value');
          contact_acceptation = $("#contact_acceptation").is(":checked");

          // annonce_id = $("div[id^='annonce_']")[0].id.replace('annonce_', '');
          $.ajax({
              type: 'POST',
              url: siteURL + "contact_mailing_borne",
              //La valeur que j'envois a ma fonction
              data: { 'contact_email': email, 'contact_acceptation': contact_acceptation, 'borne': data_borne },
              success: function() {
                  $('#email').animate({ 'width': '0px' }, "slow", function() {
                      $('#email').replaceWith('<div id="email_sent">L\'envoi du mail s\'est bien effectué</div>');
                      $('#email_sent').animate({ 'width': '100%' }, "slow");
                  });
                  window.setTimeout(function() {
                      $("#saisie-mail").fadeTo(500, 0).slideUp(500, function() {
                          $(this).hide();
                      });
                  }, 4000);
              },
              error: function() {
                  $('#email').animate({ 'width': '0px' }, "slow", function() {
                      $('#email').replaceWith('<div id="email_notsent">Une erreur est survenue lors de l\'envoi du mail</div>');
                      $('#email_notsent').animate({ 'width': '100%' }, "slow");
                  });
                  window.setTimeout(function() {
                      $("#saisie-mail").fadeTo(500, 0).slideUp(500, function() {
                          $(this).hide();
                      });
                  }, 4000);
              }
          });
          return false;
      });


      


      $('.annonce-interaction-ficheborne').on('click', function() {
          fancybox_ficheborne = new Fancybox([{
              src: $('#page_borne_envoi_fiche').html(),
              type: "html",
          }]);
      })

      $('body').on('click', '#popup_contact_fiche_borne', function() {
          email = $(".fancybox__content").find("#email").val();
          if (email != "") {
              contact_acceptation = $("#contact_acceptation").is(":checked");
              borne_id = $("div[id^='borne_']")[0].id.replace('borne_', '');
              $.ajax({
                  type: 'POST',
                  url: siteURL + "contact_mailing_borne",
                  data: { 'contact_email': email, 'contact_acceptation': contact_acceptation, 'borne': borne_id },
                  success: function(data) {
                        $(".fancybox__content").find("#popup_contact_fiche_envoye").slideDown();
                        window.setTimeout(function() {
                            fancybox_contactborne.close();
                        }, 4000);
                  },
              });
          }
          else {
                $(".fancybox__content").find("#email").css("border", "1px solid red");
          }
          return false;
      });
      $('body').on('keydown', "#email", function() { $(this).css('border', "1px solid gray")});


      $('body').on('click', '#popup_contact_mail_borne', function() {
        filledForm = true;
        $(".fancybox__content").find('input[id^="contact_"]').each( function() {
            if ($(this).attr("type") == "text" || $(this).attr("type") == "email") {
                if ($(this).val() == "") { $(this).css("border", "1px solid red"); filleForm = false}
            }
        })
        if (filledForm) {
          $.ajax({
              type: 'POST',
              url: siteURL + "contact_popup",
              data: { 
                'contact_email': $(".fancybox__content").find("#contact_email").val(),
                'contact_nom': $(".fancybox__content").find("#contact_nom").val(),
                'contact_prenom': $(".fancybox__content").find("#contact_prenom").val(),
                'contact_phone': $(".fancybox__content").find("#contact_phone").val(),
                'contact_title': $(".fancybox__content").find("#contact_title").val(),
                'contact_message': $(".fancybox__content").find("textarea").val(),
                'annonce_id': null,
              },
              success: function(data) {
                    $(".fancybox__content").find("#popup_contact_mail_envoye").slideDown();
                    window.setTimeout(function() {
                        fancybox_contactborne.close();
                    }, 4000);
              },
          });
        }
        return false;
      });
      $('body').on('keydown', 'input[id^="contact_"]', function() { $(this).css('border', "1px solid gray")});


      $('.annonce-interaction-contact-borne, .annonce-caracteristiques-contact').on('click', function() {
          fancybox_contactborne = new Fancybox([{
              src: $('#page_borne_contact').html(),
              type: "html",
          }]);
      })


  });