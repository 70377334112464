$(document).ready(function() {
    //Gestion des checkbox centres en fonction du click
    $("input[id^='centre_']").on('click', function() {
        centre_id = this.id.replace('centre_', '');

        $("input[id^='centre_']:not('#" + this.id + "')").each(function() {
            if ($(this).prop('checked', true)) {
                $(this).prop('checked', false);
            }
        });
    });

    //Je récupère la note
    $("input[id^='rating_']").on('click', function() {
        note = this.id.replace('rating_', '');
    });

    $('#noter').on('click', function() {
        prenom = $('#prenom').val();
        nom = $('#nom').val();
        commentaire = $.trim($("#commentaire").val())

        if (!prenom || !nom || !commentaire) {
            $('#fail-avis').show();
            window.setTimeout(function() {

                $("#fail-avis").fadeTo(500, 0).slideUp(500, function() {
                    // Hack : pour réafficher l'alert
                    $("#fail-avis").css('opacity', '1');
                });

            }, 4000);

            return false;
        } else {
            $.ajax({
                type: 'POST',
                url: 'avis-clients',
                data: {
                    'centre_id': centre_id,
                    'note': note,
                    'prenom': prenom,
                    'nom': nom,
                    'commentaire': commentaire
                },
                success: function() {
                    $('#depotAvis').modal('toggle');
                    $('#modal-avis-success').modal('toggle');
                },
                error: function() {
                    alert('Une erreur est survenue');
                }

            });
        }
    });


    //Etoiles moyennes avis
    //SEREZIN
    moyenne_serezin = $('#moyenne_serezin').html();

    if (moyenne_serezin <= 1.4) {
        $(".1_serezin").css("color", "#fbe122");
    } else if (1.5 <= moyenne_serezin && moyenne_serezin <= 2.4) {
        $(".2_serezin").css("color", "#fbe122");
    } else if (2.5 <= moyenne_serezin && moyenne_serezin <= 3.4) {
        $(".3_serezin").css("color", "#fbe122");
    } else if (3.5 <= moyenne_serezin && moyenne_serezin <= 4.4) {
        $(".4_serezin").css("color", "#fbe122");
    } else {
        $(".5_serezin").css("color", "#fbe122");
    }

    //MONTELIMAR
    moyenne_montelimar = $('#moyenne_montelimar').html();

    if (moyenne_serezin <= 1.4) {
        $(".1_montelimar").css("color", "#fbe122");
    } else if (1.5 <= moyenne_montelimar && moyenne_montelimar <= 2.4) {
        $(".2_montelimar").css("color", "#fbe122");
    } else if (2.5 <= moyenne_montelimar && moyenne_montelimar <= 3.4) {
        $(".3_montelimar").css("color", "#fbe122");
    } else if (3.5 <= moyenne_montelimar && moyenne_montelimar <= 4.4) {
        $(".4_montelimar").css("color", "#fbe122");
    } else {
        $(".5_montelimar").css("color", "#fbe122");
    }

});