$(document).ready(function() {
    $("body").on('change', "#home_search_marque", function() {
        formCount = new FormData();
        
        if ($("#home_search_marque").val().length > 0) {
            marquesSelectionnees = $("#home_search_marque").val();
            form = new FormData();
            optionsRecherche['marque'] = "";
            marquesSelectionnees.forEach(function(m) {
                optionsRecherche['marque'] += m.replace('marque_', '') + ',';
            });

            form.append('marque', optionsRecherche['marque']);
            $("#home_search_count").find('span').fadeOut(function() {
                $(this).html('<span><i class="fas fa-circle-notch fa-spin"></i></span>').fadeIn();
            });

            updateHomeCounter(optionsRecherche);

            $.ajax({
                type: 'POST',
                url: siteURL + 'json/modeles',
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $("#home_search_modele").empty();
                    for (marque in data) {
                        if (marque > 0) { $("#home_search_modele").append('<option data-divider="true"></option>'); }
                        for (id in data[marque]) {
                            if (data[marque][id]['nb'] > 0) {
                                $("#home_search_modele").append('<option value=' + data[marque][id]['id'] + '>' + id + "</option>");
                                $("#home_search_modele").prop('disabled', false);
                            }
                        }
                    };
                    $("#home_search_modele").selectpicker('refresh');
                }
            });
        } else {
            delete(optionsRecherche['marque']);
            delete(optionsRecherche['modele']);
            updateHomeCounter(optionsRecherche);
            $("#home_search_modele").empty();
            $("#home_search_modele").prop('disabled', 'disabled');
            $("#home_search_modele").selectpicker('refresh');
        }
    });

    $("body").on('change', "#home_search_modele", function() {
        modelesSelectionnees = $("#home_search_modele").val();
        form = new FormData();

        if ($("#home_search_modele").val().length > 0) {
            optionsRecherche['modele'] = "";
            modelesSelectionnees.forEach(function(m) {
                form.append('modele', m.replace('modele_', '') + ',');
                optionsRecherche['modele'] += m.replace('modele_', '') + ',';
            });
        } else {
            delete(optionsRecherche['modele']);
        }
        updateHomeCounter(optionsRecherche);
    });

    $("body").on('change', "#home_search_kilometrage", function() {

        formCount = new FormData();
        if ($("#home_search_kilometrage").val().length > 0) {
            optionsRecherche['kilometrage'] = "";
            bornage_kilometrique = $("#home_search_kilometrage").val()[0].replace('km_', '');
            formCount.append('kilometrage', bornage_kilometrique);
            optionsRecherche['kilometrage'] = bornage_kilometrique;
        } else {
            delete(optionsRecherche['kilometrage']);
        }
        updateHomeCounter(optionsRecherche);
    });


    $("body").on('change', "#home_search_budget", function() {

        formCount = new FormData();
        if ($("#home_search_budget").val().length > 0) {
            optionsRecherche['prix_min'] = "";
            optionsRecherche['prix_max'] = "";
            bornage_budget = $("#home_search_budget").val()[0].split(",");
            formCount.append('prix_max', bornage_budget[1]);
            formCount.append('prix_min', bornage_budget[0]);
            optionsRecherche['prix_max'] = bornage_budget[1];
            optionsRecherche['prix_min'] = bornage_budget[0];
        } else {
            delete(optionsRecherche['prix_min']);
            delete(optionsRecherche['prix_max']);
        }
        updateHomeCounter(optionsRecherche);
    });

    // Au click sur le bouton recensant le nombre de v�hicules
    $("#home_search_count").on('click', function() {
        // Pour v�rifier s'il est cliquable on v�rifie s'il a la classe "cursor-not-allowed" sur son �l�ment
        if (!$(this).hasClass("cursor-not-allowed")) {
            urlToGo = siteURL + "achat-vehicules-neufs-et-occasions?options=[";
            Object.keys(optionsRecherche).forEach(function(o) {
                urlToGo += o + ":" + optionsRecherche[o] + ";";
            })
            urlToGo += "]";
            window.location.href = urlToGo;
        }
    });

});

// Factorisation mise � jour du compteur sur la page d'accueil
function updateHomeCounter(optionsRecherche) {
    $("#home_search_count").find('span').fadeOut(function() { $(this).html('<span><i class="fas fa-circle-notch fa-spin"></i></span>').fadeIn(); });

    formCount = new FormData();
    formCount.append('recherche', JSON.stringify(optionsRecherche));
    $.ajax({
        type: 'POST',
        url: siteURL + 'json/count',
        data: formCount,
        processData: false,
        contentType: false,
        success: function(data, status) {
            switch (data.number) {
                // Aucun r�sultat
                case 0:
                    if (!$("#home_search_count").hasClass("cursor-not-allowed")) { $("#home_search_count").toggleClass("cursor-pointer").toggleClass("cursor-not-allowed"); }
                    $("#home_search_count").find('span').fadeOut(function() { $(this).html('Aucun v&eacute;hicule').fadeIn(); });
                    break;
                // Juste un seul v�hicule
                case 1:
                    if ($("#home_search_count").hasClass("cursor-not-allowed")) { $("#home_search_count").toggleClass("cursor-pointer").toggleClass("cursor-not-allowed"); }
                    $("#home_search_count").find('span').fadeOut(function() { $(this).html('Voir le v&eacute;hicule').fadeIn(); });
                    break;
                // Un nombre de v�hicules sup�rieur � 1
                default:
                    if ($("#home_search_count").hasClass("cursor-not-allowed")) { $("#home_search_count").toggleClass("cursor-pointer").toggleClass("cursor-not-allowed"); }
                    $("#home_search_count").find('span').fadeOut(function() { $(this).html("Voir les " + data.number + " v&eacute;hicules").fadeIn(); });
                    break;
            }
        }
    });
}