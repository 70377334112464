$('body').on('click', "span[id^='button-next-']", function() {
    var number = this.id.split(/[- ]+/).pop();

    if (number != 3) {
        if (number == 1) {
            form_correct = true;
            if ($('#reprise_marque').val() == "") {
                $('#reprise_marque').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_marque').removeClass('is-invalid');
            }
            if ($('#reprise_modele').val() == "" ) {
                $('#reprise_modele').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_modele').removeClass('is-invalid');
            }
            regex = new RegExp(/^\d{4}$/);
            if ( $('#reprise_annee').val() == "" || !(regex.test($('#reprise_annee').val())) ) {
                $('#reprise_annee').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_annee').removeClass('is-invalid');
            }
            if ($('#reprise_mise_en_circulation').val() == "" ) {
                $('#reprise_mise_en_circulation').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_mise_en_circulation').removeClass('is-invalid');
            }

            if (form_correct == true) {
                $('#reprise-info_marque').text($('#reprise_marque').val());
                $('#reprise-info_modele').text($('#reprise_modele').val());
                $('#reprise-info_annee').text($('#reprise_annee').val());
                $('#reprise-info_mise_en_circulation').text($('#reprise_mise_en_circulation').val());

                $('#reprise_marque').removeClass('is-invalid');
                $('#reprise_modele').removeClass('is-invalid');
                $('#reprise_annee').removeClass('is-invalid');
                $('#reprise_mise_en_circulation').removeClass('is-invalid');

                next = true;
            }
        } else if (number == 2) {
            form_correct = true;
            if ($('#reprise_finition').val() == "" ) {
                $('#reprise_finition').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_finition').removeClass('is-invalid');
            }
            if ($('#reprise_boite_de_vitesse').val() == "" ) {
                $('#reprise_boite_de_vitesse').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_boite_de_vitesse').removeClass('is-invalid');
            }
            if ($('#reprise_energie').val() == "") {
                $('#reprise_energie').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_energie').removeClass('is-invalid');
            }
            regex = new RegExp(/^\d{10}$/);
            if ($('#reprise_nb_portes').val() == "" || !($('#reprise_nb_portes').val().match("[0-9]"))) {
                $('#reprise_nb_portes').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_nb_portes').removeClass('is-invalid');
            }

            if ($('#reprise_kilometrage').val() == "" || !($('#reprise_kilometrage').val().match("[0-9]"))) {
                $('#reprise_kilometrage').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_kilometrage').removeClass('is-invalid');
            }
            if ($('#reprise_immatriculation').val() == "" || $('#reprise_immatriculation').val().length > 10) {
                $('#reprise_immatriculation').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_immatriculation').removeClass('is-invalid');
            }
            if ($('#reprise_couleur').val() == "" ) {
                $('#reprise_couleur').addClass('is-invalid');
                form_correct = false;
            } else {
                $('#reprise_couleur').removeClass('is-invalid');
            }

            if (form_correct == true) {
                $('#reprise_finition').removeClass('is-invalid');
                $('#reprise_boite_de_vitesse').removeClass('is-invalid');
                $('#reprise_energie').removeClass('is-invalid');
                $('#reprise_nb_portes').removeClass('is-invalid');
                $('#reprise_kilometrage').removeClass('is-invalid');
                $('#reprise_immatriculation').removeClass('is-invalid');
                $('#reprise_couleur').removeClass('is-invalid');

                next = true;
            }
        }

        if (typeof next !== 'undefined' && next == true) {
            $('#reprise-titre-'+(parseInt(number)+1)).addClass('reprise_titre_actif');
            $('#reprise-arrow_actif_'+number).removeClass('d-block');
            $('#reprise-arrow_actif_'+(parseInt(number)+1)).addClass('d-block');
            $('#reprise_formulaire_'+number).addClass('d-none');
            $('#reprise_formulaire_'+(parseInt(number)+1)).removeClass('d-none');
        }
        
    } else {
        // Validation des données

        form_correct = true;

        if (typeof $('input[name="reprise_civilite"]:checked').val() === "undefined") {
            $('#reprise-info_civilite').css('color', 'red');
            form_correct = false;
        } else {
            $('#reprise-info_civilite').css('color', 'black');
        }

        if ($('#reprise_nom').val() == "") {
            $('#reprise_nom').addClass('is-invalid');
            form_correct = false;
        } else {
            $('#reprise_nom').removeClass('is-invalid');
        }

        if ($('#reprise_prenom').val() == "") {
            $('#reprise_prenom').addClass('is-invalid');
            form_correct = false;
        } else {
            $('#reprise_prenom').removeClass('is-invalid');
        }

        if ($('#reprise_email').val() == "") {
            $('#reprise_email').addClass('is-invalid');
            form_correct = false;
        } else {
            $('#reprise_email').removeClass('is-invalid');
        }

        regex = new RegExp(/^\d{10}$/);
        if ($('#reprise_telephone').val() == "" || !(regex.test($('#reprise_telephone').val()))) {
            $('#reprise_telephone').addClass('is-invalid');
            form_correct = false;
        } else {
            $('#reprise_telephone').removeClass('is-invalid');
        }

        if ($('#reprise_code_postal').val() == "" || !($('#reprise_code_postal').val().match("[0-9]")) || $('#reprise_code_postal').val().length > 10) {
            $('#reprise_code_postal').addClass('is-invalid');
            form_correct = false;
        } else {
            $('#reprise_code_postal').removeClass('is-invalid');
        }

        if ($('#reprise_ville').val() == "") {
            $('#reprise_ville').addClass('is-invalid');
            form_correct = false;
        } else {
            $('#reprise_ville').removeClass('is-invalid');
        }

        if (typeof $('input[name="reprise_new_vehicule"]:checked').val() === "undefined") {
            $('#reprise-info_new_vehicule').css({
                "color": "red",
                "border": "1px solid red",
            });
            form_correct = false;
        } else {
            $('#reprise-info_new_vehicule').css({
                "color": "black",
                "border": "1px solid black",
            });
        }

        if (typeof $('input[name="reprise_rgpd"]:checked').val() === "undefined") {
            $('#reprise-info_rgpd').css({
                "color": "red",
            });
            form_correct = false;
        } else {
            $('#reprise-info_rgpd').css({
                "color": "black",
            });
        }



        if (form_correct == false) {
            return false;
        }

        // Enregistrement des données

        var form = new FormData();

        form.append('reprise_marque', $('#reprise_marque').val());
        form.append('reprise_modele', $('#reprise_modele').val());
        form.append('reprise_annee', $('#reprise_annee').val());
        form.append('reprise_mise_en_circulation', $('#reprise_mise_en_circulation').val());

        for (const iterator of [1, 2, 3]) {
            let file = $("#reprise_file_"+iterator)[0].files[0];
            console.log(iterator, file);
            if (file) {
                console.log(iterator, file);
                form.append('reprise_file_'+iterator, new File([file], file.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify()+"."+file.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: file.type }));
            }
        }
        form.append('reprise_finition', $('#reprise_finition').val());
        form.append('reprise_boite_de_vitesse', $('#reprise_boite_de_vitesse').val());
        form.append('reprise_energie', $('#reprise_energie').val());
        form.append('reprise_nb_portes', $('#reprise_nb_portes').val());
        form.append('reprise_kilometrage', $('#reprise_kilometrage').val());
        form.append('reprise_immatriculation', $('#reprise_immatriculation').val())
        form.append('reprise_couleur', $('#reprise_couleur').val());

        form.append('reprise_civilite', $('input[name="reprise_civilite"]:checked').val());
        form.append('reprise_nom', $('#reprise_nom').val());
        form.append('reprise_prenom', $('#reprise_prenom').val());
        form.append('reprise_email', $('#reprise_email').val());
        form.append('reprise_telephone', $('#reprise_telephone').val());
        form.append('reprise_code_postal', $('#reprise_code_postal').val());
        form.append('reprise_ville', $('#reprise_ville').val());
        form.append('reprise_new_vehicule', $('input[name="reprise_new_vehicule"]:checked').val());
        form.append('reprise_captcha', grecaptcha.getResponse());

        $.ajax({
            type: 'POST',
            url: siteURL+'reprise-de-votre-vehicule-formulaire/save',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                if (data == "ok") {
                    $('#modal-reprise-success').modal('show');
                    send_conversion('MNFjCO3Hw-YCEIb295ID');
                } else if (data == "failure_captcha")  {
                    $('#modal-reprise-failure_captcha').modal('show');
                } else if (data == "piratage")  {
                    $('#modal-reprise-failure_piratage').modal('show');
                } else if (data == "envoi")  {
                    $('#modal-reprise-failure_envoi').modal('show');
                } else {
                    $('#modal-reprise-failure').modal('show');
                }
            }
        });
        return false;

        // Envoie par mail des informations



    }
});


$('body').on('click', "span[id^='button-previous-']", function() {
    var number = this.id.split(/[- ]+/).pop();
    var previous = parseInt(number) - 1;

    $('#reprise-titre-'+number).removeClass('reprise_titre_actif');
    $('#reprise-titre-'+previous).addClass('reprise_titre_actif');
    $('#reprise-arrow_actif_'+number).removeClass('d-block');
    $('#reprise-arrow_actif_'+previous).addClass('d-block');

    $('#reprise_formulaire_'+number).addClass('d-none');
    $('#reprise_formulaire_'+previous).removeClass('d-none');

});

// Restricts input for the set of matched elements to the given inputFilter function.
(function($) {
  $.fn.inputFilter = function(inputFilter) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  };
}(jQuery));


$(document).ready(function() {
  $("#reprise_annee").inputFilter(function(value) {
    return /^\d*$/.test(value);    // Allow digits only, using a RegExp
  });
});

$('body').on('change keyup', "#reprise_marque", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_modele", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_annee", function(e) {
    $(this).val($(this).val().match(/\d/gi));
    if ($(this).val() != "") {
        $(this).val($(this).val().replace(/,/g, ""));
    }
});

$('body').on('change keyup', "#reprise_finition", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_boite_de_vitesse", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_energie", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_nb_portes", function(e) {
    $(this).val($(this).val().match(/\d/gi));
    if ($(this).val() != "") {
        $(this).val($(this).val().replace(/,/g, ""));
    }
});
$('body').on('change keyup', "#reprise_kilometrage", function(e) {
    $(this).val($(this).val().match(/\d/gi));
    if ($(this).val() != "") {
        $(this).val($(this).val().replace(/,/g, ""));
    }
});
$('body').on('change keyup', "#reprise_immatriculation", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_couleur", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});

$('body').on('change keyup', "#reprise_nom", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#reprise_prenom", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('blur', "#reprise_email", function(e) {
    $(this).val($(this).val().match(regexAntiPiratageMail));
});
$('body').on('change keyup', "#reprise_telephone", function(e) {
    $(this).val($(this).val().match(/^\d{0,10}/gi));
    if ($(this).val() != "") {
        $(this).val($(this).val().replace(/,/g, ""));
    }
});
$('body').on('blur', "#reprise_telephone", function(e) {
    $(this).val($(this).val().match(regexAntiPiratageTel)[0]);
});
$('body').on('change keyup', "#reprise_code_postal", function(e) {
    $(this).val($(this).val().match(/^\d{0,5}/gi));
});
$('body').on('change keyup', "#reprise_ville", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});

$('body').on('change', "[id^='reprise_file_']", function(e) {
    let idInput = this.id.replace("reprise_file_", "");
    const files = this.files;
    if (FileReader && files && files.length) {
        console.log(files[0].name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify()+"."+files[0].name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify());
        var fr = new FileReader();
        fr.onload = function () {
            $("#reprise_file_"+idInput+"-default_label").addClass("d-none");
            $("#reprise_file_"+idInput+"-img_label")[0].src = fr.result;
            $("#reprise_file_"+idInput+"-img_label").removeClass("d-none");
        }
        fr.readAsDataURL(files[0]);
    } else {
        $("#reprise_file_"+idInput+"-img_label").addClass("d-none");
        $("#reprise_file_"+idInput+"-img_label")[0].src = "";
        $("#reprise_file_"+idInput+"-default_label").removeClass("d-none");
    }
});