$(document).ready(function() {
    if ($("#rdv_lieu").val() == "site") {
        $("#pole-marchand").prop("disabled", true);
    }

    if ($('.form_datetime').length > 0) {
        // On prépare la date de rendez-vous pour qu'elle soit disponible à partir de T + 2 heures
        let dateAujourdhui = new Date(Date.now()+7200000);
        $(".form_datetime").datetimepicker({
            format: 'dd/mm/yyyy hh:ii',
            minuteStep: 30,
            todayBtn: true,
            language: 'fr',
            daysOfWeekDisabled: '0',
            fontAwesome: true,
            hoursDisabled: [0,1,2,3,4,5,6,7,8,19,20,21,22,23],
            startDate: dateAujourdhui,
        });
    }

    //Envois rendez vous
    $("#rdv-submit").on('click', function(e) {
        e.preventDefault();
        correct = false;
        naissance = false;

        if ($('#rdv_client_nom').val().length == 0
            || $('#rdv_client_prenom').val().length == 0
            || $('#rdv_date').val().length == 0
            // || $('#rdv_client_adresse').val().length == 0
            // || $('#rdv_client_ville').val().length == 0
            // || !validateDateNaissance($('#rdv_client_naissance').val())
            || !validateTelephone($('#rdv_client_telephone').val())
            || !validateEmail($('#rdv_client_mail').val())
            // || !validateCodePostal($('#rdv_client_cp').val())
            ) {

            if ($('#rdv_client_nom').val().length == 0) { $('#rdv_client_nom').addClass('is-invalid');
            } else { $('#rdv_client_nom').removeClass('is-invalid'); }

            if ($('#rdv_client_prenom').val().length == 0) { $('#rdv_client_prenom').addClass('is-invalid');
            } else { $('#rdv_client_prenom').removeClass('is-invalid'); }

            // if ($('#rdv_client_adresse').val().length == 0) { $('#rdv_client_adresse').addClass('is-invalid');
            // } else { $('#rdv_client_adresse').removeClass('is-invalid'); }

            // if ($('#rdv_client_ville').val().length == 0) { $('#rdv_client_ville').addClass('is-invalid');
            // } else { $('#rdv_client_ville').removeClass('is-invalid'); }

            if ($('#rdv_date').val().length == 0) { $('#rdv_date').addClass('is-invalid');
            } else { $('#rdv_date').removeClass('is-invalid'); }

            // if (!validateDateNaissance($('#rdv_client_naissance').val())) { $('#rdv_client_naissance').addClass('is-invalid'); naissance = true;
            // } else { $('#rdv_client_naissance').removeClass('is-invalid'); }

            if (!validateTelephone($('#rdv_client_telephone').val())) { $('#rdv_client_telephone').addClass('is-invalid');
            } else { $('#rdv_client_telephone').removeClass('is-invalid'); }

            if (!validateEmail($('#rdv_client_mail').val())) { $('#rdv_client_mail').addClass('is-invalid');
            } else { $('#rdv_client_mail').removeClass('is-invalid'); }

            if (!validateCodePostal($('#rdv_client_cp').val())) { $('#rdv_client_cp').addClass('is-invalid');
            } else { $('#rdv_client_cp').removeClass('is-invalid'); }

        } else {
            $('#rdv_client_nom').removeClass('is-invalid');
            $('#rdv_client_prenom').removeClass('is-invalid');
            // $('#rdv_client_naissance').removeClass('is-invalid');
            $('#rdv_client_telephone').removeClass('is-invalid');
            $('#rdv_client_mail').removeClass('is-invalid');
            // $('#rdv_client_adresse').removeClass('is-invalid');
            // $('#rdv_client_cp').removeClass('is-invalid');
            // $('#rdv_client_ville').removeClass('is-invalid');
            $('#rdv_date').removeClass('is-invalid');

            if (typeof $('input[name="rdv_rgpd"]:checked').val() === "undefined") {
                $('#rdv-info_rgpd').css({ "color": "red" });
            } else {
                $('#rdv-info_rgpd').css({ "color": "black" }); correct = true;
            }
        }

        if (correct) {

            data = new FormData();

            data.append('rdv_client_nom', $('#rdv_client_nom').val());
            data.append('rdv_client_prenom', $('#rdv_client_prenom').val());
            // Si l'utilisateur la rentre à la main
            // if ($('#rdv_client_naissance').val().includes('/')) {
            //     dateNaissance = $('#rdv_client_naissance').val().split('/');
            //     dateResult = dateNaissance[2]+"-"+dateNaissance[1]+"-"+dateNaissance[0];
            // } else {
            //     dateResult = $('#rdv_client_naissance').val();
            // }
            // data.append('rdv_client_naissance', dateResult);
            data.append('rdv_client_telephone', $('#rdv_client_telephone').val());
            data.append('rdv_client_mail', $('#rdv_client_mail').val());
            // data.append('rdv_client_adresse', $('#rdv_client_adresse').val());
            // data.append('rdv_client_cp', $('#rdv_client_cp').val());
            // data.append('rdv_client_ville', $("#rdv_client_ville").val());
            data.append('rdv_centre', $('select[name="rdv_centre"]').val());
            data.append('rdv_lieu', $('select[name="rdv_lieu"]').val());
            data.append('rdv_date', $('#rdv_date').val());
            data.append('rdv_complementaire', $('#rdv_complementaire').val());
            data.append('rdv_captcha', grecaptcha.getResponse());

            $.ajax({
                type: 'POST',
                url: siteURL + "prendre-rendez-vous/save",
                data: data,
                processData: false,
                contentType: false,
                success: function(data) {
                    if (data == "ok") {
                        $('#modal-rdv-success').modal('show');
                        gtag('event', 'conversion', {
                            'send_to': 'AW-845019910/vn79CJrilOICEIb295ID',
                        });
                    } else if (data == "failure_captcha") {
                        $('#modal-rdv-captcha').modal('show');
                    } else if (data == "failure_piratage")  {
                        $('#modal-rdv-piratage').modal('show');
                    } else if (data == "failure_envoi")  {
                        $('#modal-rdv-envoi').modal('show');
                    } else {
                        $('#modal-rdv-fail').modal('show');
                    }

                },
                error: function() {
                    $('#modal-rdv-fail').modal('show');
                }

            });
        } else {
            if (naissance == true) {
                $('#modal-rdv-naissance').modal('show');
            } else {
                $('#modal-rdv-fail').modal('show');
            }
        }

    });

    function validateDateNaissance(day) {
        var re = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
        var re2 = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

        // Pour la vérification si la date de naissance est bien antérieur à aujourd'hui
        //On prend la date d'aujourd'hui et choisie en timestamp pour les comparer facilement
        let dateAujourdhui = Date.now();
        let dateChoisie = Date.parse(day);
        if (dateChoisie > dateAujourdhui) { return false; }
        if (re.test(day) == true || re2.test(day) == true) { return true; }
        return false;
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validateTelephone(telephone) {
        if (telephone.length == 10 && telephone.match("[0-9]")) { return true; }
        return false;
    }

    function validateCodePostal(codePostal) {
        if (codePostal.length <= 10 && codePostal.length != 0 && codePostal.match("[0-9]")) { return true; }
        return false;
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
});

$('body').on('change keyup', "#rdv_client_nom", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#rdv_client_prenom", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('blur', "#rdv_client_mail", function(e) {
    $(this).val($(this).val().match(regexAntiPiratageMail));
});
$('body').on('change keyup', "#rdv_client_telephone", function(e) {
    $(this).val($(this).val().match(/^\d{0,10}/gi));
    if ($(this).val() != "") {
        $(this).val($(this).val().replace(/,/g, ""));
    }
});
$('body').on('blur', "#rdv_client_telephone", function(e) {
    $(this).val($(this).val().match(regexAntiPiratageTel)[0]);
});
$('body').on('change keyup', "#rdv_client_adresse", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#rdv_client_cp", function(e) {
    $(this).val($(this).val().match(/^\d{0,5}/gi));
});
$('body').on('change keyup', "#rdv_client_ville", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change keyup', "#rdv_complementaire", function(e) {
    $(this).val($(this).val().replace(regexAntiPiratage, ""));
});
$('body').on('change', "#rdv_lieu", function(e) {
    if ($("#rdv_lieu").val() == "site") {
        $("#pole-marchand").prop("disabled", true).prop("selected", false);
    } else {
        $("#pole-marchand").prop("disabled", false);
    }
});
