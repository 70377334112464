$(document).ready(function() {

    if ($('#splide-carousel_actualite').length > 0) {
        new Splide('#splide-carousel_actualite', {
            type: 'loop',
            perPage: 1,
            autoplay: true,
            arrows: true,
            interval: 7500,
            pagination: false,
        }).mount();
    }

    // Gestion des sliders sur la Home
    if ($('#splide-new_info').length > 0) {
        new Splide('#splide-new_info', {
            type: 'loop',
            perPage: 1,
            autoplay: true,
            arrows: false,
            interval: 10000,
        }).mount();
    }

    if ($('#splide-avis').length > 0) {
        new Splide('#splide-avis', {
            type: 'loop',
            perPage: 1,
            autoplay: true,
            arrows: false,
            pagination: false,
            interval: 7500,
        }).mount();
    }
    if ($('#splide-vehicules_deals').length > 0) {
        new Splide('#splide-vehicules_deals', {
            type: 'loop',
            perPage: 3,
            breakpoints: {
                1199.98: {
                    perPage: 2,
                },
                575.98: {
                    perPage: 1,
                },
            },
            autoplay: true,
            arrows: false,
            pagination: false,
            interval: 13000,
        }).mount();
    }

    if ($('#splide-home_carrosserie').length > 0) {
        new Splide('#splide-home_carrosserie', {
            type: 'loop',
            perPage: 4,
            breakpoints: {
                1199.98: {
                    drag: false,
                },
                767.98: {
                    perPage: 2,
                    drag: true,
                    pagination: true,
                },
                575.98: {
                    perPage: 1,
                    pagination: true,
                    drag: true,
                },
            },
            drag: false,
            autoplay: false,
            arrows: false,
            pagination: false,
            interval: 15000,
        }).mount();
    }

    if ($('#splide-home_actualites').length > 0) {
        new Splide('#splide-home_actualites', {
            type: 'loop',
            perPage: 3,
            breakpoints: {
                1199.98: {
                    drag: false,
                },
                767.98: {
                    perPage: 2,
                    pagination: true,
                    drag: true,
                },
                575.98: {
                    perPage: 1,
                    pagination: true,
                    drag: true,
                },

            },
            drag: false,
            autoplay: false,
            arrows: false,
            pagination: false,
            interval: 15000,
        }).mount();
    }

    if ($('#splide-avis_actualites').length > 0) {
        new Splide('#splide-avis_actualites', {
            type: 'loop',
            perPage: 3,
            breakpoints: {
                767.98: {
                    perPage: 2,
                },
                575.98: {
                    perPage: 1,
                },

            },
            arrows: false,
            interval: 15000,
        }).mount();
    }

    if ($('#splide-offreemploi_actualites').length > 0) {
        new Splide('#splide-offreemploi_actualites', {
            type: 'loop',
            perPage: 3,
            breakpoints: {
                767.98: {
                    perPage: 2,
                },
                575.98: {
                    perPage: 1,
                },
            },
            arrows: false,
            pagination: true,
            interval: 15000,
        }).mount();
    }

    if ($('#splide-annonce-images').length > 0) {
        var splide_annonce_images = new Splide('#splide-annonce-images', {
            perPage: 1,
            breakpoints: {
                1599.98: {
                    perPage: 2,
                    padding: '12em',
                },
                991.98: {
                    perPage: 2,
                    padding: "5em",
                },
                767.98: {
                    perPage: 1,
                    padding: "5em",
                },
                575.98: {
                    perPage: 1,
                    padding: "0em",
                },
            },
            //height: '200px',
            autoHeight: true,
            start: 0,
            type: 'loop',
            padding: '35em',
            autoplay: true,
            pagination: true,
            arrows: false,
            gap: '0.5rem',
        });

        splide_annonce_images.on("pagination:mounted", function(data) {
            if (window.innerWidth < 767.98) {
                $("#more-pictures").html("<i class='fas fa-camera pe-2'></i> <span>1</span>/"+data.items.length);
            }
        });

        splide_annonce_images.on("move", function(data) {
            if (window.innerWidth < 767.98) {
                $("#more-pictures").find('span').text(data+1);
            }
        });

        splide_annonce_images.on('mounted', function() {
            $('#splide-placeholder').addClass('d-none');
        });

        splide_annonce_images.mount();

    }

});