$( document ).ready(function() {
  if ($('#data_count_vehicule').length > 0) {
    // console.log($('#data_count_vehicule').data('count'));
    for (var i = 1; i <= $('#data_count_vehicule').data('count'); i++) {
      // console.log($('.annonce-moreimages-'+i));
      if ($('.annonce-moreimages-'+i).length > 0) {
        new Splide( '.annonce-moreimages-'+i, {
          perPage: 3,
          breakpoints: {
            576: {
              perPage: 2,
            },
          },
          //height: '200px',
          autoHeight: true,
          start: 0,
          type: 'loop',
          autoplay: true,
          pagination: false,
          gap: '0.5rem',
        } ).mount();
      }
    }
  }

  // Permets lors d'un click sur l'image du slider de remplacer l'image principale
  $('body').on('click', '[data-slider^="slider-refresh-"]', function() {
    var number =  $(this).data('slider').split('-').pop();
    var url = $(this).attr('src');
    var clicked = $(this).attr('src').split('/medium/');

    $('.annonce-pictures-' + number).each(function() {
      if ($(this).is(':parent') && $(this).children().attr("src") == url.replace('medium', 'hd')) {
        $(this).children().trigger('click');
        return false;
      }
    });

    $('.annonce-pictures-' + number).each(function() {
      if ($(this).attr('href').includes(clicked[1]) == false) {
        $(this).empty();
      } else {
        $(this).html('<img id="annonce-zoom-' + number+'" src="' + url.replace('medium', 'hd') + '" class="img-fluid" />');
      }
    });
    $('#annonce-zoom-' + number).attr("src", url.replace('medium', 'hd'));
    $('#annonce-zoom-' + number).parent().attr("href", url.replace('medium', 'hd'));
  });

  $('.price-frais-route').on('click', function() {
    $('#modal-classic-frais-route').modal('show');
  })
});
