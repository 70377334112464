$(document).ready(function() {
    //Envois candidature
    $("#formCandidature").on('submit', function(e) {
        e.preventDefault();

        offre_id = $('#offre_id').val();

        data = new FormData();

        data.append('candidat_civilite',$("select#candidat_civilite option:selected").val());
        data.append('candidat_nom', $('#candidat_nom').val());
        data.append('candidat_prenom', $('#candidat_prenom').val());
        data.append('candidat_tel', $('#candidat_tel').val());
        data.append('candidat_mail', $('#candidat_mail').val());
        data.append('candidat_domaine', $('#candidat_domaine').val());
        data.append('candidat_exp', $("#candidat_exp").children("option:selected").val());
        data.append('candidat_linkedin', $("#candidat_linkedin").val());
        data.append('candidat_cv', $("#candidat_cv")[0].files[0]);
        data.append('candidat_motivation', $("#candidat_motivation")[0].files[0]);

        $.ajax({
            type: 'POST',
            url: siteURL + "offre_candidat_" + offre_id,
            data: data,
            processData: false,
            contentType: false,
            success: function() {
                $("#modal-candidature-success").modal('show');
            },
            error: function() {
                $("#modal-candidature-failure").modal('show');
            }

        });
    });
});