$(document).ready(function() {
    //Choix offre emploi
    $("span[id^='offre_emploi_']").on("click", function() {
        offre_id = this.id.replace('offre_emploi_', '');


        $('.content_offre').hide();
        $("#detail_emploi_" + offre_id).show();

    });

    //Smooth scrolling
    $(".scrollable").on('click', function() {
        elementToGo = $(this).data("scrollto");

        var ancre = $("#" + elementToGo);

        if (ancre.length > 0) {
            $("html, body").animate({ scrollTop: ancre.offset().top - 135 }, 1500);
        }
    });

});