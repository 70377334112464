$(document).ready(function() {
	if ($('#modal-contact-success').length > 0) {
		$('#modal-contact-success').modal('show');
	} else if ($('#modal-contact-error').length > 0) {
		$('#modal-contact-error').modal('show');
	} else if ($('#modal-contact-piratage').length > 0) {
		$('#modal-contact-piratage').modal('show');
	}

	$('body').on('change keyup', "#form_nom", function(e) {
		$(this).val($(this).val().replace(regexAntiPiratage, ""));
	});
	$('body').on('change keyup', "#form_prenom", function(e) {
		$(this).val($(this).val().replace(regexAntiPiratage, ""));
	});
	$('body').on('blur', "#form_email", function(e) {
		$(this).val($(this).val().match(regexAntiPiratageMail));
	});
	$('body').on('change keyup', "#form_tel_portable", function(e) {
		$(this).val($(this).val().match(/^\d{0,10}/gi));
		if ($(this).val() != "") {
			$(this).val($(this).val().replace(/,/g, ""));
		}
	});
	$('body').on('blur', "#form_tel_portable", function(e) {
		$(this).val($(this).val().match(/^\d{0,10}/gi));
		if ($(this).val() != "") {
		  $(this).val($(this).val().replace(/,/g, ""));
		}
	});
	$('body').on('change keyup', "#form_sujet", function(e) {
		$(this).val($(this).val().replace(regexAntiPiratage, ""));
	});
	$('body').on('change keyup', "#form_message", function(e) {
		$(this).val($(this).val().replace(regexAntiPiratage, ""));
	});
});